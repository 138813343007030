import { useState, useRef, lazy, Suspense } from 'react';
import './App.css';
//import About from './components/About/About';
//import Portfolio from './components/Portfolio/Portfolio';
//import Contact from './components/Contact/Contact';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Particles from './components/Background/Particles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import Sidebar from './components/Sidebar/Sidebar';
import { LangContext } from './langContext';

const About = lazy(() => import('./components/About/About'));
const Portfolio = lazy(() => import('./components/Portfolio/Portfolio'));
const Contact = lazy(() => import('./components/Contact/Contact'));

const lang = window.navigator.language;

function App() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [tab, setter] = useState(99);
    const prevTab = useRef(1);
    
    const right = debounce(() => {
        setTab(Math.min(tab + 1, 2));
    }, 100);
    const left = debounce(() => {
        setTab(Math.max(tab - 1, 0));
    }, 100);

    const [heroMounted, setHeroMounted] = useState(true);

    const heroRef = useRef<(HTMLDivElement | null)>(null);

    const mainRef = useRef<(HTMLDivElement | null)>(null);
    const aboutRef = useRef<(HTMLElement | null)>(null);
    const portfolioRef = useRef<(HTMLElement | null)>(null);
    const contactRef = useRef<(HTMLElement | null)>(null);

    function setTab(index: number) {
        prevTab.current = tab;
        setter(index);
    }

    function animateOutHero() {
        const center = document.querySelector('.btns-center-wrap') as HTMLDivElement;
        if (!center) return;
        
        // vent før titel og action knap er animeret ud
        setTimeout(() => {
            // crossfade header og scroll button
            center.classList.remove('fade');
            // vent på at header animerer vidde
            setTimeout(() => {
                center.classList.remove('init');
                // vent før main section fader ind
                const toUnhide = Array.from(document.getElementsByClassName('hide')) as HTMLDivElement[];
                toUnhide.forEach(x => x.classList.remove('hide'));
                const panBtns = Array.from(document.getElementsByClassName('tab-pan-btn')) as HTMLButtonElement[];
                panBtns.forEach(x => x.style.opacity = '');
                // unmount
                setTimeout(() => {
                    setHeroMounted(false);
                    setTab(1);
                }, 1000)
            }, 1000)
        }, 600)
    }

    const sidebarToggle = () => (!sidebarOpen) ? setSidebarOpen(true) : () => {};
    const sidebarBtnIcon = (sidebarOpen) ? faTimes : faBars;

    const panLClass = `tab-pan-btn${(tab < 1) ? ' disable' : ''}`;
    const panRClass = `tab-pan-btn${(tab > 1) ? ' disable' : ''}`;

    return (
        <LangContext.Provider value={lang}>
            <div className="App" id="App">
                <Particles />
                <Header setTab={setTab} heroMounted={heroMounted} tab={tab} />
                {heroMounted ? (
                    <Hero animateOutHero={animateOutHero} ref={heroRef} />
                ) : (
                    <div className="main" id="main" ref={mainRef}>
                        <Suspense fallback={null}>
                            <About right={right} tab={tab} prevTab={prevTab.current} ref={aboutRef} />
                        </Suspense>
                        <Suspense fallback={null}>
                            <Portfolio left={left} right={right} tab={tab} prevTab={prevTab.current} ref={portfolioRef} />
                        </Suspense>
                        <Suspense fallback={null}>
                            <Contact left={left} tab={tab} prevTab={prevTab.current} ref={contactRef} />
                        </Suspense>
                    </div>
                )}
                <button aria-label="Sidebar button" id="sidebar-btn" className="hide" onClick={sidebarToggle}>
                    <FontAwesomeIcon icon={sidebarBtnIcon} />
                </button>
                {sidebarOpen && <Sidebar setTab={setTab} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
            
                <button aria-label="Previous section" className={panLClass} style={{ left: '20px', opacity: '0' }} onClick={left}>
                    <FontAwesomeIcon size="3x" icon={faCaretLeft} />
                </button>
                <button aria-label="Next section" className={panRClass} style={{ right: '30px', opacity: '0' }} onClick={right}>
                    <FontAwesomeIcon size="3x" icon={faCaretRight} />
                </button>
            </div>
        </LangContext.Provider>
    )
}

export default App;
