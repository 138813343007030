import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import toggleDarkMode from '../../toggleDarkMode';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSpring, a } from 'react-spring';
import DarkModeToggle from '../DarkModeToggle/DarkModeToggle';
import { useLang } from '../../langContext';

function Header(props: { setTab: (tab: number) => void, heroMounted: boolean, tab: number }) {
    const lang = useLang();
    const { setTab, heroMounted, tab } = props;
    
    const [btnsWidth, setBtnsWidth] = useState(['', '']);
    // const fade = undefined;
    const fade = useSpring({
        config: { duration: 1000 },
        delay: 500,
        opacity: (heroMounted) ? 0 : 1
    });

    const btnsRef = useRef<(HTMLDivElement | null)>(null);
    const btn1Ref = useRef<(HTMLDivElement | null)>(null);
    const btn2Ref = useRef<(HTMLDivElement | null)>(null);
    const btn3Ref = useRef<(HTMLDivElement | null)>(null);
    const indicatorRef = useRef<(HTMLDivElement | null)>(null);
    
    useEffect(() => {
        function updWidth() {
            if (btnsRef.current) {
                setBtnsWidth([btnsRef.current.clientWidth + 'px', btnsRef.current.clientHeight + 'px'])
            }
            if (indicatorRef.current && btn1Ref.current && btn2Ref.current && btn3Ref.current && tab < 50) {
                const arr = [btn1Ref.current, btn2Ref.current, btn3Ref.current];
                indicatorRef.current.style.transform = `translate3d(${arr[tab].offsetLeft + indicatorRef.current.clientWidth / 2}px, 0px, 0px)`
            }
        }
        updWidth();
        window.addEventListener('resize', updWidth);
        return () => {
            window.removeEventListener('resize', updWidth);
        }
    }, [setBtnsWidth, tab])

    const tab1 = () => setTab(0);
    const tab2 = () => setTab(1);
    const tab3 = () => setTab(2);
    //const dmBtnClick = (e: ChangeEvent<HTMLInputElement>) => toggleDarkMode(e.target.checked);

    

    const about = (lang === 'da') ? 'Om' : 'About';
    const contact = (lang === 'da') ? 'Kontakt' : 'Contact';

    return (
        <div id="header" className="header">
            
            <div className="btns-center" id="btns-center">
                <div className="btns-center-wrap fade init" style={{ width: btnsWidth[0], height: btnsWidth[1], position: 'absolute' }} />
                <div className="header-btns" ref={btnsRef}>
                    <a.div className="btn-wrap" id="tab-btn-0" style={fade} ref={btn1Ref}>
                        <button name="About button" className="header-btn" onClick={tab1}>
                            <span title={about}>{about}</span>
                        </button>
                    </a.div>
                    <a.div className="btn-wrap" id="tab-btn-1" style={fade} ref={btn2Ref}>
                        <button name="Portfolio button" className="header-btn" onClick={tab2}>
                            <span title="Portfolio">Portfolio</span>
                        </button>
                    </a.div>
                    <a.div className="btn-wrap" id="tab-btn-2" style={fade} ref={btn3Ref}>
                        <button name="Contact button" className="header-btn" onClick={tab3}>
                            <span title={contact}>{contact}</span>
                        </button>
                    </a.div>
                    <a.div id="section-indicator" className="hide" style={fade} ref={indicatorRef} />
                </div>
            </div>
            {/*<a.div className="dm-btn-outer hide" style={fade}>
                <label className="switch">
                    <FontAwesomeIcon className="dm-icon" icon={faSun} style={{ right: '12px' }} />
                    <FontAwesomeIcon className="dm-icon" icon={faMoon} style={{ left: '12px' }} />
                    <input aria-label="Enable dark mode?" type="checkbox" id="dm-btn" onChange={dmBtnClick} />
                    <span className="slider" />
                </label>
                <button aria-label="Unused" id="canvas-upd-btn" style={{ display: 'none', position: 'absolute', visibility: 'hidden' }} />
            </a.div>*/}
            <a.div className="dm-btn-outer hide" style={fade}>
                <DarkModeToggle width={80} id={'dark-mode-toggle'} toggleFunction={toggleDarkMode} />
                {/*<button aria-hidden="true" id="canvas-upd-btn" style={{ display: 'none', position: 'absolute', visibility: 'hidden', width: '0', height: '0' }} />*/}
            </a.div>
        </div>
    )
}

export default Header;