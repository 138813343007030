import { interpolate } from "flubber";

export const arrowColors = [
    '#ffbc5d',
    '#ffdb5d',
    '#c2cdca'
];

export const arrowPath = "M42,79c3.368,2.315,2.988,2.052,8.257,5.672L209.926,194.351C236.811,212.819,248.015,217,256,217s19.137-4.146,45.967-22.575L462.038,84.469c5.086-3.493,3.122-1.9,7.962-5.469,8.461-5.946-.223-16-8-12-5.6,2.547-3.662,1.689-10.01,4.616l-150.058,69.2C275.227,153.133,265.459,157,256,157s-19.41-3.951-46.314-16.358L60.038,71.629C53.673,68.694,55.683,69.421,50,67,39.112,61.531,33.748,73.86,42,79Z";
const barPath = "M85.655,8.89c1.242,1.493,1.1,1.323,3.077,3.693l87.413,104.91C198.259,144.032,209.747,152,218,152s19.677-7.905,41.718-34.406L347.17,12.448a42.284,42.284,0,0,0,2.96-3.558c3-3.749-5.747-9.816-9.042-7.443-2.527,1.54-1.64,1.018-4.517,2.806l-84.4,52.43C232.886,68.664,225.4,72.64,217.94,72.64s-15.09-4.062-34.519-16.121L99.219,4.261C96.332,2.469,97.3,2.91,94.686,1.447,90.045-1.788,82.48,5.642,85.655,8.89Z";

export default function doInterpolate(arrow: (SVGPathElement | null), animateOutHero: () => void, dur: number) {
    if (!arrow) return;
    const check = document.getElementById('arrow-glow');
    if (check) {
        check.style.display = 'none';
    }
    const interpolator = interpolate(arrowPath, barPath);
    const start = Date.now();
    function looper() {
        const now = Date.now();
        const timeScale = Math.min((now - start) / dur, 1);
        arrow?.setAttribute("d", interpolator(timeScale));
        if (timeScale === 1) {
            return;
        }
        window.requestAnimationFrame(looper);
    }
    looper();
    animateOutHero();
}