import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import { useLang } from '../../langContext';

function Sidebar(props: { setTab: (t: number) => void, sidebarOpen: boolean, setSidebarOpen: (isOpen: boolean) => void }) {
    const { setTab, setSidebarOpen } = props;
    const lang = useLang();
    const [openMe, setOpenMe] = useState(false);
    const texts = lang === 'da' ? ["Om", "Portfolio", "Kontakt"] : ["About", "Portfolio", "Contact"];
    useEffect(() => {
        setOpenMe(true);
    }, []);
    const delayedClose = () => {
        setOpenMe(false);
        setTimeout(() => {
            setSidebarOpen(false);
        }, 500)
    }
    const click0 = () => {
        setTab(0);
        delayedClose()
    };
    const click1 = () => {
        setTab(1);
        delayedClose()
    };
    const click2 = () => {
        setTab(2);
        delayedClose()
    };
    const className = (openMe) ? 'open' : '';
    return (
        <>
            <div id="sidebar-bg" className={className} onClick={delayedClose} />
            <div id="sidebar" className={className}>
                <button onClick={click0}>{texts[0]}</button>
                <button onClick={click1}>{texts[1]}</button>
                <button onClick={click2}>{texts[2]}</button>
                <button aria-label="Close" id="close-btn" onClick={delayedClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        </>
    )
}

export default Sidebar;