import { memo, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import ThreeParticles from './ThreeParticles';
import { ResizeObserver } from "@juggle/resize-observer";

function ThreeCanvas() {
    const [sizes, setSizes] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    useEffect(() => {
        function updSize() {
            setSizes({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize', updSize);
        return () => {
            window.removeEventListener('resize', updSize);
        }
    }, [setSizes])

    return (
        <Canvas
            camera={{ fov: 75, position: [0, 0, 2], aspect: sizes.width / sizes.height, near: 0.1, far: 100, isPerspectiveCamera: true }}
            dpr={Math.min(window.devicePixelRatio, 2)}
            style={{ position: 'fixed', top: '0', left: '0' }}
            resize={{ polyfill: ResizeObserver }}
        >
            {(sizes.width > 799) && <ThreeParticles />}
        </Canvas>
    )
}

export default memo(ThreeCanvas);