import { useEffect, useRef } from 'react';
import star from '../../images/tiny-star.png';
import { PointsMaterial, Points, Color, TextureLoader } from 'three';
import { useFrame } from '@react-three/fiber';
import { throttle } from 'lodash';

type Theme = [a: Color, b: number];
const dark: Theme = [new Color('rgb(6,6,6)'), 0.5];
const light: Theme = [new Color('rgb(255,255,255)'), 1];

const map = new TextureLoader().load(star);
const posArray = new Float32Array(5000 * 3);
for (let i = 0; i < 5000 * 3; i++) {
    posArray[i] = (Math.random() - 0.5) * 5;
}

function ThreeParticles(props: JSX.IntrinsicElements['points']) {
    const dmBtn = document.getElementById('dark-mode-toggle') as HTMLInputElement | null;
    const ref = useRef<Points>(null!);
    const materialRef = useRef<PointsMaterial>(null!);

    const theme = dmBtn ? dmBtn.checked ? dark : light : light;

    const mouse = useRef({ x: 0, y: 0, flag: 0 });

    useEffect(() => {
        const dmBtn = document.getElementById('dark-mode-toggle') as HTMLInputElement;
        const w = window.innerWidth;
        const h = window.innerHeight;

        function updateColor() {
            if (dmBtn.checked) {
                materialRef.current.color = dark[0];
                materialRef.current.opacity = dark[1];
            }
            else {
                materialRef.current.color = light[0];
                materialRef.current.opacity = light[1];
            }
        }

        const updMouse = (ev: globalThis.MouseEvent) => {
            mouse.current.x = ev.clientX / w * 20 - 10;
            mouse.current.y = ev.clientY / h * 20 - 10;
            mouse.current.flag = 1;
        }
        const throttled = throttle(updMouse, 60);

        window.addEventListener('mousemove', throttled);
        dmBtn.addEventListener('change', updateColor);
        return () => {
            window.removeEventListener('mousemove', throttled);
            dmBtn.removeEventListener('change', updateColor);
        }
    }, [])

    useFrame((state, delta) => {
        // state.pointer.
        if (mouse.current.flag === 0) {
            ref.current.rotation.y += delta * 0.05;
        }
        ref.current.rotation.x -= mouse.current.y * delta * 0.015;
        ref.current.rotation.y -= mouse.current.x * delta * 0.015;
        
        // ref.current.rotation.x -= state.pointer.y * delta * 0.015;
        // ref.current.rotation.y -= state.pointer.x * delta * 0.015;
    })
    return (
        <points ref={ref} {...props}>
            <bufferGeometry attach="geometry">
                <bufferAttribute attach="attributes-position" count={posArray.length / 3} array={posArray} itemSize={3} />
            </bufferGeometry>
            <pointsMaterial ref={materialRef} attach="material" size={0.02} color={theme[0]} map={map} transparent={true} opacity={theme[1]} />
        </points>
    )
}

export default ThreeParticles