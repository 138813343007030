import './Particles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import ThreeCanvas from './ThreeCanvas';
import { isMobile } from 'react-device-detect';

const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const items = arr.map(x => <FontAwesomeIcon key={x} className="particle" icon={faCode} size="2x" />)

function Particles() {
    return (
        <div className="particle-wrapper">
            {(!isMobile) && <ThreeCanvas />}
            {(!isMobile) && <div className="particle-container">
                {items}
            </div>}
        </div>
    )
}

export default Particles;