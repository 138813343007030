import { useState, ChangeEvent, useEffect, memo } from "react";
import styled from 'styled-components';
import MorphingIcon from './MorphingIcon';

interface Props {
    w: number
    isOn: boolean
}
interface TogglerProps extends Props {
    togglerSize: number
}

const padPx = 6;
const heightScale = 0.55;
const duration = 400;

const Input = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
`;

/*
 &:before {
        content: "";
        position: absolute;
        left: 18%;
        top: 35%;
        width: 64%;
        height: 30%;
        background-color: black;
        opacity: 0.5;
        border-radius: inherit;
    }
*/

const Background = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    width: ${(p: Props) => p.w}px;
    height: ${(p: Props) => p.w * heightScale}px;
    padding: ${padPx}px;
    background: var(--bg-gradient);

    border-radius: 999px;
    box-shadow: inset 3px 3px 6px 1px rgba(0,0,0,0.2), inset 0 1px 1px rgba(0,0,0,0.2),  1px 1px 2px rgba(255,255,255,0.4);
    transition: all ${duration}ms;
    filter: brightness(1) drop-shadow(0 0 4px rgba(255,255,255,0));
    &:hover {
        filter: brightness(1.05) drop-shadow(0 0 4px rgba(255,255,255,0.15));
    }
    &:hover svg {
        /*transform: rotate(${(p: Props) => (p.isOn) ? 360 : 90 }deg);*/
    }
  }
`;

// metal look // background: repeating-radial-gradient(circle at 50% 50%, rgba(200,200,200,.2) 0%, rgba(200,200,200,.2) 2%, transparent 2%, transparent 3%, rgba(200,200,200,.2) 3%, transparent 3%), conic-gradient(white 0%, silver 10%, white 35%, silver 45%, white 60%, silver 70%, white 80%, silver 95%, white 100%);

/*
 svg:nth-of-type(1) {
        transform: translate3d(0, ${(p: Props) => p.isOn ? '-150%' : '0%'}, 0);
    }
    svg:nth-of-type(2) {
        transform: translate3d(0, ${(p: Props) => p.isOn ? '0%' : '150%'}, 0);
    }
 */

const Toggler = styled.span`
    position: relative;
    width: ${(p: TogglerProps) => p.togglerSize}px;
    height: 100%;
    border-radius: 999px;
    transform: translate3d(${(p: TogglerProps) => p.isOn ? p.w - (padPx * 2) - p.togglerSize : 0}px, 0, 0);
    transition: all ${duration}ms cubic-bezier(.51,-0.34,.48,1.33), background 0.2s linear;
    background: ${(p: TogglerProps) => p.isOn ? '#333' : '#fff'};
    
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3), inset 1px 1px 4px rgba(255,255,255,0.33), inset -1px -1px 4px rgba(0,0,0,0.16);

    svg {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        transition: all ${duration}ms cubic-bezier(.51,-0.34,.48,1.33);
    }
`;

const DarkModeToggle = (props: {
    width: number,
    toggleFunction: (switchOn: boolean) => void,
    id: string,
    onByDefault?: (boolean | undefined)
}) => {
    const { width, toggleFunction, onByDefault, id } = props;
    const defValue = (onByDefault) ? true : false;
    const [isOn, setIsOn] = useState(defValue);

    useEffect(() => {
        toggleFunction(isOn);
    }, [isOn, toggleFunction])

    const togglerSize = (width * heightScale) - (padPx * 2);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setIsOn(e.target.checked);

    return (
        <>
            <Input id={id} type="checkbox" defaultChecked={defValue} onChange={onChange} />
            <Background w={width} isOn={isOn} htmlFor={id}>
                <Toggler w={width} togglerSize={togglerSize} isOn={isOn}>
                    <MorphingIcon isOn={isOn} duration={duration} />
                </Toggler>
            </Background>
        </>
    );
};

export default memo(DarkModeToggle)