import { interpolate } from 'flubber';
import { Spring, animated } from 'react-spring';

const sunPath = 'M158.05,19.47c-6.41,25.06.66,45.68,12.9,65.12,9.47,15,15.69,31.05,15.4,48.25A138.87,138.87,0,0,0,174,140.71L75,75l66.43,97.87c-1.19,1.64-2.35,3.36-3.47,5.05-18.85-8.49-36.54-10.17-53.39-6.91-23.83,4.61-44.06-.38-65.18-13,13.18,22.25,32.86,31.83,55.26,36.92,17.36,3.94,33.05,10.85,45,23.28a142.63,142.63,0,0,0-3.2,14.22L0,256.06l116.22,22.23c.32,2,.7,4,1.11,6-19.33,7.31-33.08,18.6-42.69,32.83C61.05,337.22,43.31,348,19.47,354c25.06,6.41,45.65-.74,65.09-13,15-9.47,31-15.69,48.25-15.4A141.09,141.09,0,0,0,140.67,338L75,437.08l97.92-66.56c1.63,1.19,3.29,2.4,5,3.51-8.49,18.85-10.14,36.62-6.88,53.48,4.61,23.82-.41,44-13,65.09,22.25-13.18,31.84-32.86,36.92-55.26,3.94-17.36,10.86-33,23.28-45a140.07,140.07,0,0,0,14.23,3.2L256,512l22.22-116.22c2-.32,4-.7,6-1.11,7.31,19.34,18.61,33.08,32.83,42.69C337.18,451,348,468.69,354,492.53c6.4-25.06-.74-45.65-13-65.09-9.48-15.05-15.64-31-15.31-48.28a140.94,140.94,0,0,0,12.3-7.83L437,437l-66.47-97.95c1.17-1.62,2.33-3.28,3.43-4.94,18.85,8.52,36.61,10.14,53.47,6.88,23.83-4.61,44,.41,65.1,13-13.19-22.25-32.83-31.76-55.23-36.84-17.34-3.94-33-11-45-23.4a139,139,0,0,0,3.11-14.19L512,256.06,395.75,233.75c-.32-2-.71-4-1.12-6,19.35-7.31,33.08-18.6,42.7-32.83,13.59-20.11,31.36-30.81,55.2-36.84-25.06-6.41-45.68.65-65.12,12.9-15.06,9.48-31,15.63-48.29,15.31a140.94,140.94,0,0,0-7.83-12.3L437,75l-98,66.39c-1.62-1.17-3.28-2.33-4.94-3.43,8.5-18.84,10.17-36.53,6.91-53.39-4.61-23.83.38-44.06,13-65.18-22.25,13.19-31.75,32.83-36.84,55.23-3.94,17.38-11,33.12-23.44,45.09a139.11,139.11,0,0,0-14.14-3.23L256,0,233.71,116.25c-2,.32-4,.71-6,1.12-7.31-19.34-18.6-33.08-32.83-42.7C174.78,61.08,164.09,43.31,158.05,19.47Z';
const moonPath = 'M32 256c0-123.8 100.3-224 223.8-224c11.36 0 29.7 1.668 40.9 3.746c9.616 1.777 11.75 14.63 3.279 19.44C245 86.5 211.2 144.6 211.2 207.8c0 109.7 99.71 193 208.3 172.3c9.561-1.805 16.28 9.324 10.11 16.95C387.9 448.6 324.8 480 255.8 480C132.1 480 32 379.6 32 256z';
// outside function for performance reasons, alternatively inside useMemo hook if unmounting
const interpolator = interpolate(sunPath, moonPath);

function MorphingIcon(props: { isOn: boolean, duration: number }) {
    const { isOn, duration } = props;
    
    return (
        <svg width="100%" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <Spring reset config={{ duration }} from={{ t: (isOn) ? 0 : 1 }} to={{ t: (isOn) ? 1 : 0 }}>
                {({ t }) => <animated.path d={t.to(interpolator)} style={{ fill: 'rgb(240,210,0)', stroke: 'rgba(0,0,0,0.75)', strokeWidth: '8px' }} />}
            </Spring>
        </svg>
    )
}

export default MorphingIcon