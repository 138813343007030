import { useState, useRef, useEffect, TouchEvent } from "react";
import { easings, useSpring } from "react-spring";
import moveDividers from "../../moveDividers";
import doInterpolate from "./HeroScrollPath";

function HeroLogic(props: { animateOutHero: () => void }) {
    const { animateOutHero } = props;

    const [shouldUnmount, setShouldUnmount] = useState(false);
    const arrow = useRef<(SVGPathElement | null)>(null);
    const heroScrollRef = useRef<(HTMLButtonElement | null)>(null);

    const cascader = {
        config: { duration: 1000, easing: easings.easeInOutBack },
        x: (shouldUnmount) ? window.innerWidth * -1 : 0
    }

    const delayBetween = 150;

    const cascade1 = useSpring(cascader);
    const cascade2 = useSpring({ ...cascader, delay: delayBetween });
    const cascade3 = useSpring({ ...cascader, delay: delayBetween * 2 });

    useEffect(() => {
        if (shouldUnmount) {
            moveDividers();
            setTimeout(() => {
                doInterpolate(arrow.current, animateOutHero, 1000);
            }, 300)
        }
    }, [shouldUnmount, animateOutHero])

    const arrowMover = useSpring({
        config: { duration: 1500, easing: easings.easeInOutExpo },
        delay: 900,
        x: '-50%',
        y: (shouldUnmount) ? (window.innerHeight) * -1 : 0,
        opacity: (shouldUnmount) ? 0 : 1
    })

    const arrowSkinFader = useSpring({
        config: { duration: 300 },
        opacity: (shouldUnmount) ? 0 : 1
    })

    const animate = () => {
        setShouldUnmount(true);
    }

    const clickEmu = () => {
        if (heroScrollRef.current) {
            heroScrollRef.current.click();
        }
    }

    const checkWheel = (e: any) => {
        if (e.deltaY > 0 && heroScrollRef.current) {
            clickEmu();
        }
    }

    const checkTouch = (e: TouchEvent<HTMLDivElement>) => {
        if (e) {

        }
    }

    return { arrow, animate, arrowMover, clickEmu, heroScrollRef, checkWheel, cascade1, cascade2, cascade3, arrowSkinFader }
}

export default HeroLogic