function moveDividers() {
    //const wave0 = document.getElementById('wave0') as HTMLDivElement;
    //const wave1 = document.getElementById('wave1') as HTMLDivElement;
    //const wave2 = document.getElementById('wave2') as HTMLDivElement;
    const s0 = document.querySelector('#wave0 svg') as SVGElement;
    const s1 = document.querySelector('#wave1 svg') as SVGElement;
    const s2 = document.querySelector('#wave2 svg') as SVGElement;
    if (s0 && s1 && s2) {
        const arr = [s0, s1, s2];
        arr.forEach((x) => {
            x.style.opacity = '0';
        })
        s0.style.transform = `translateX(${7.5}%)`;
        s1.style.transform = `scale(2)`;
        s2.style.transform = `scale(2)`;
    }
}

export default moveDividers;