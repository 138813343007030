import './Hero.css';
//import down from './images/down.svg';
//import square from './images/square.svg';
import { forwardRef, ForwardedRef } from 'react';
import SvgBg from '../../SvgBg';
//import { motion } from 'framer-motion';
import { animated } from 'react-spring';
import { arrowPath } from './HeroScrollPath';
import HeroLogic from './HeroLogic';
import { useLang } from '../../langContext';

const Hero = forwardRef((props: { animateOutHero: () => void }, ref: ForwardedRef<(HTMLDivElement | null)>) => {
    const lang = useLang();

    const { arrow, animate, arrowMover, clickEmu, heroScrollRef, checkWheel, cascade1, cascade2, cascade3, arrowSkinFader } = HeroLogic(props);

    function ScrollDown() {  
        const onClick = () => {
            if (arrow.current && heroScrollRef.current) {
                heroScrollRef.current.style.pointerEvents = 'none';
                heroScrollRef.current.style.cursor = 'default';
                heroScrollRef.current = null;
                animate();
            }
        }
        
        return (
            <animated.button aria-label="Scroll to portfolio" style={arrowMover} id="hero-scroll" onTouchEnd={clickEmu} onClick={onClick} className="hero-btn" ref={heroScrollRef}>
                
                <svg width="100%" viewBox="0 0 500 250" xmlns="http://www.w3.org/2000/svg">
                    <animated.path style={{ fill: '#c2cdca' }} ref={arrow} d={arrowPath} />
                </svg>
                <animated.svg width="100%" viewBox="0 0 500 250" xmlns="http://www.w3.org/2000/svg" id="arrow-glow" style={arrowSkinFader}>
                    <path d={arrowPath} />
                </animated.svg>
                
            </animated.button>
        )
    }

    const description = (lang === 'da') ? 'Webudvikler/programmør' : 'Web developer/Programmer';

    return (
        <div className="hero-container" id="hero-container" onWheel={checkWheel} ref={ref}>
            <SvgBg />
            <div id="hero-content">
                <animated.h1 style={cascade1}>Christoffer Valentin Hildebrandt</animated.h1>
                <animated.h2 style={cascade2}>{description}</animated.h2>
                <animated.div style={cascade3} id="hero-action-wrap">
                    <button name="Show portfolio" id="hero-action" onTouchEnd={clickEmu} onClick={clickEmu}>Portfolio</button>
                </animated.div>
                <ScrollDown />
            </div>
        </div>
    )
})

export default Hero;