
function toggleDarkMode(on: boolean) {
    const style = document.documentElement.style;
    if (on) { // DARK MODE
        style.setProperty('--bg', 'rgb(24,24,24)');
        style.setProperty('--bg2', 'rgba(60,60,60,1)');
        style.setProperty('--bg-trans', 'rgba(24,24,24,0.5)');
        style.setProperty('--theme', 'rgba(40,40,120,1)');
        style.setProperty('--theme-var', 'rgba(50,50,200,1)');
        style.setProperty('--text', 'antiquewhite');
        style.setProperty('--outline', 'rgba(255,255,255,0.3)');
        style.setProperty('--inset-opac', '0');
        style.setProperty('--line-gradient', 'linear-gradient(170deg, var(--theme) 0%, rgba(240,150,0) 50%, var(--theme) 100%)');
    }
    else { // LIGHT MODE
        style.setProperty('--bg', 'rgb(250,235,215)');
        style.setProperty('--bg2', 'antiquewhite');
        style.setProperty('--bg-trans', 'rgba(255,255,255,0.5)');
        style.setProperty('--theme', 'lightblue');
        style.setProperty('--theme-var', 'rgba(50,50,200,1)');
        style.setProperty('--text', 'black');
        style.setProperty('--outline', 'rgba(0,0,0,0.3)');
        style.setProperty('--inset-opac', '0.3');
        style.setProperty('--line-gradient', 'linear-gradient(170deg, orange 0%, rgba(240,240,0) 50%, orange 100%)');
    }

    /*setTimeout(() => {
        document.getElementById('canvas-upd-btn')?.click();
    }, 0)*/
}

export default toggleDarkMode;